<template>
  <slot v-if="loading" name="loader" :classes="$attrs.class">
    <TheLoader v-if="loading" :size="loadingSize" :class="$attrs.class" />
  </slot>
  <div
    v-show="!loading"
    class="relative flex flex-col size-full"
    :class="[
      $attrs.class,
      {
        '!overflow-hidden': sendLoading,
      },
    ]"
  >
    <slot v-if="sendLoading && !hideSendingLoader" name="sendLoading">
      <div
        class="absolute size-full bg-white/90 backdrop-blur flex flex-col gap-y-5 items-center justify-center font-semibold"
      >
        <TheLoader class="h-fit" size="xlarge" />
        <span>Sending</span>
      </div>
    </slot>

    <template v-if="!showSuccess">
      <slot name="header" />

      <div
        v-show="!sendLoading"
        :id="containerId"
        ref="formRef"
        class="bm-custom-form"
      />

      <slot name="footer">
        <NuxtLink
          v-if="showPrivacyPolicyLink"
          to="/privacy"
          target="_blank"
          class="text-s-900 underline text-xs text-center mt-2"
          no-prefetch
        >
          Privacy Policy
        </NuxtLink>
      </slot>
    </template>
    <slot
      v-else
      name="success"
      :refresh-form="refreshForm"
      :close-form="closeForm"
    >
      <div class="flex w-full items-center justify-center h-full">
        <DefaultSuccessMessage
          :message="props.successMessage"
          :with-close-button="withCloseButton"
          @close="closeForm"
        />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import TheLoader from '~/components/TheLoader.vue'
import type { TCommon } from '~/src/types/common'
import DefaultSuccessMessage from '~/components/forms/DefaultSuccessMessage.vue'

import { useAuthStore } from '~/stores/auth'
import type { TLoaderSize } from '~/src/types/markets'

type TProps = {
  formId: string
  portalId?: string
  region?: string
  withCloseButton?: boolean
  successMessage?: string
  formType?: string
  formName?: string
  hideSendingLoader?: boolean
  showPrivacyPolicyLink?: boolean
  loadingSize?: TLoaderSize
}

type TBeforeSubmit = {
  form: HTMLFormElement
  values: TCommon
}

type TSubmitted = {
  form: HTMLFormElement
  values: TCommon
  refreshForm: () => void
}

type AllowedInputElement =
  | HTMLInputElement
  | HTMLTextAreaElement
  | HTMLSelectElement

const config = useRuntimeConfig()
const { $rudderstack } = useNuxtApp()
const authStore = useAuthStore()

const userEmail = computed(() => authStore.user?.email || '')

const props = withDefaults(defineProps<TProps>(), {
  withCloseButton: true,
  showPrivacyPolicyLink: true,
  formType: 'contact-form',
  formName: 'Contact Us',
  hideSendingLoader: false,
  loadingSize: 'xlarge',
})

const emit = defineEmits<{
  'on-submitted': [args: TSubmitted]
  'on-before-init': []
  'on-ready': [form: HTMLFormElement]
  'on-before-submit': [args: TBeforeSubmit]
  close: []
}>()

const hasStartedFillingForm = ref(false)
const formRef = ref<HTMLDivElement | null>(null)
const containerId = useId()
const formInstanceId = `hs-form-${containerId}`
const loading = ref(true)
const sendLoading = ref(false)
const showSuccess = ref(false)

const hsPortalId = computed(
  () => props.portalId ?? config.public.hubspotPortalId,
)
const hsRegion = computed(() => props.region ?? config.public.hubspotRegion)

const observeFormChanges = (form: HTMLFormElement) => {
  const addListenersToInputs = () => {
    form
      .querySelectorAll<AllowedInputElement>('input, textarea, select')
      .forEach((input) => {
        if (!input.dataset.listenerAdded) {
          // Prevent duplicate listeners
          input.dataset.listenerAdded = 'true'

          // Listen for value changes
          input.addEventListener('input', (event: Event) => {
            // const target = event.target as AllowedInputElement

            trackInputChanges(event)
            trackFormStart()
          })
        }
      })
  }

  // Attach listeners to existing inputs
  addListenersToInputs()

  // Observe DOM for newly added inputs
  const observer = new MutationObserver(() => {
    addListenersToInputs()
  })

  observer.observe(form, { childList: true, subtree: true })
}

function parseFormInput(
  event: Event,
): { name: string; value: string; fieldType: string } | null {
  const target = event.target as HTMLElement

  if (target instanceof HTMLInputElement) {
    return { fieldType: target.type, name: target.name, value: target.value }
  }
  if (target instanceof HTMLTextAreaElement) {
    return { fieldType: 'textarea', name: target.name, value: target.value }
  }
  if (target instanceof HTMLSelectElement) {
    return { fieldType: 'select', name: target.name, value: target.value }
  }

  return null // Not a form element
}

const trackInputChanges = (event: Event) => {
  const { name, value, fieldType } = parseFormInput(event)

  if (!name || !value || !fieldType) return

  $rudderstack.trackFormFieldChange({
    form_id: props.formId,
    form_type: props.formType,
    form_name: props.formName,
    field_name: name,
    field_type: fieldType,
    field_value: value,
  })
}

const trackFormStart = () => {
  if (!hasStartedFillingForm.value) {
    hasStartedFillingForm.value = true

    $rudderstack.trackFormStart({
      form_id: props.formId,
      form_type: props.formType,
      form_name: props.formName,
    })
  }
}

const onFormSubmitted = (
  form: HTMLFormElement,
  { submissionValues: values },
) => {
  sendLoading.value = false
  showSuccess.value = true
  emit('on-submitted', { form, values, refreshForm })

  $rudderstack.trackFormSubmissions({
    form_id: props.formId,
    form_type: props.formType,
    form_name: props.formName,
  })
}

const onBeforeFormInit = () => {
  loading.value = true
  emit('on-before-init')
}

const setOnInputWebsiteUrl = (form: HTMLFormElement) => {
  if (import.meta.server) return

  const websiteInput: HTMLInputElement = form.querySelector(
    'input[name*="form_completed_on"]',
  )
  if (websiteInput) websiteInput.value = window.location.href
}

const setEmailWhenHidden = (form: HTMLFormElement) => {
  if (import.meta.server) return

  const emailInput: HTMLInputElement = form.querySelector('input[name="email"]')

  if (!emailInput || emailInput?.type !== 'hidden') return

  emailInput.value = userEmail.value
}

const setCountryName = (form: HTMLFormElement) => {
  if (import.meta.server) return

  const countryInput: HTMLInputElement = form.querySelector(
    'select[id^="phone_ext"]',
  )
  const countryName = form.querySelector<HTMLInputElement>(
    'input[name*="hs_country_region_code"]',
  )

  if (!countryInput || !countryName) return

  countryName.value = countryInput.value
}

const onFormReady = (form: HTMLFormElement) => {
  setOnInputWebsiteUrl(form)
  setEmailWhenHidden(form)

  emit('on-ready', form)
  loading.value = false
  showSuccess.value = false

  nextTick(() => {
    observeFormChanges(form)
  })
}

const onBeforeFormSubmit = (form: HTMLFormElement, values: TCommon) => {
  sendLoading.value = true
  setCountryName(form)
  emit('on-before-submit', { form, values })
}

const loadForm = (formId: string) => {
  if (import.meta.server || !window?.hbspt) return

  nextTick(() => {
    window?.hbspt.forms.create({
      formInstanceId,
      formId,
      region: hsRegion.value,
      portalId: hsPortalId.value,
      target: `#${containerId}`,
      onFormSubmitted,
      onBeforeFormSubmit,
      onBeforeFormInit,
      onFormReady,
    })
  })
}

const refreshForm = () => {
  loadForm(props.formId)
  loading.value = false
  showSuccess.value = false
}

const closeForm = () => {
  refreshForm()
  emit('close')
}

watch(
  () => props.formId,
  (formId) => {
    loadForm(formId)
  },
  { immediate: true },
)
</script>
